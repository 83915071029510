@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  font-family: 'Oxygen', sans-serif;
  background-color: '#F3F6F9';
}

@layer components {
  .label {
    @apply text-sm font-bold text-gray-700 tracking-wide dark:text-gray-200;
  }
  .btn-primary {
    @apply py-3 px-4 bg-valencia-500 text-white font-semibold shadow-md hover:bg-valencia-600 
       focus:outline-none focus:ring-2 focus:ring-calypso-400 focus:ring-opacity-75 
       rounded-full tracking-wide;
  }
}
